@import url(https://fonts.googleapis.com/css2?family=Outfit&display=swap);
:root {
  --primary-color: #202020;
  --secondary-color: #fff;
}

.button-container {
  display: flex;
  align-items: center;
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;

  border: 2px solid var(--primary-color);
  border-radius: 10px;
}

.button-container-dark {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.button-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Outfit", sans-serif;
}

.button-store-name {
  font-size: 20px;
  font-weight: bold;
}

.button-title {
  font-size: 12px;
}

.buttons-container {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

